<template>
  <v-form v-model="formValid">
    <v-layout wrap>
      <v-flex
        v-for="(field, index) in fields"
        :key="index"
        px-2
        x12
        sm6
      >
        <component
          :is="field.type"
          v-model="patientDetails[field.key]"
          :label="field.label"
          :append-text="field.unit"
          :required="field.isRequired"
          :disabled="field.isReadonly"
          :validate="validate"
          :field-key="field.key"
          :rules="field.rules"
          :items="field.items"
          :loading="field.loading"
          :maxlength="field.maxlength"
          :error-message="field.errorMessage"
          :allow-user-to-edit="allowUserToEdit"
          @input="onChange($event, field)"
        />
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import String from "@/components/form/StringInput";
import Dropdown from "@/components/form/Dropdown";
import patientValidateMixin from "@/mixins/patientValidateMixin";
import { get, orderBy } from "lodash";

export default {
  name: "PatientBasic",

  components: {
    String,
    Dropdown,
  },

  mixins: [patientValidateMixin],

  props: {
    allowUserToEdit: { type: Boolean, required: false, default: true },
    schema: { type: Array, required: true, default: () => [] },
    existingPatientDetails: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    newPatient: { type: Boolean, required: true, default: true },
  },

  data() {
    return {
      validate: false,
      formValid: false,
      patientDetails: {},
      fields: [],
    };
  },
  watch: {
    existingPatientDetails: {
      immediate: true,
      handler(value) {
        this.patientDetails = value;
      },
    },
    patientDetails(value) {
      this.$emit("update:existingPatientDetails", value);
    },
    newPatient(value) {
      this.updateField("mrn", "isReadonly", !value);
    },
  },
  created() {
    this.fields = orderBy(this.schema, ["order"], ["asc"]);
    this.updateField("mrn", "isReadonly", false);
  },

  methods: {
    async onChange(value, field) {
      if (this.newPatient && value && field.validator !== undefined) {
        const { treatment } = this.$route.params;
        const data = await this.remoteValidate(
          field.key,
          field.validator[0].apiUrl,
          {
            mrn: value,
            mode: "new",
            treatmentId: treatment,
          }
        );
        const isExist = get(data, "isExist", false);
        const errorMessage = get(data, "message", "");
        this.updateField(
          field.key,
          "errorMessage",
          isExist ? errorMessage : ""
        );
      }
    },
    updateField(key, property, value) {
      const index = this.fields.findIndex((field) => field.key === key);
      if (index !== -1) {
        this.fields[index][property] = value;
        this.fields = this.fields.map((field, i) => {
          if (i === index) {
            return { ...field, [property]: value };
          }
          return field;
        });
      }
    },
  },
};
</script>
